import { getCookie, setCookie } from 'components/Common/CommonFunctions';
import Keycloak from 'keycloak-js';
import { Config } from 'keycloak/Config';
import { useNavigate } from 'react-router-dom'

const noRights = {
  role: 'No Rights',
  permissions: [],
}


const contributor = {
  role: 'Faculty',
  permissions: [
    'profile_view',
    'profile_edit',
    'iia_list',
    'iia_create',
    'iia_view',
    'iia_edit',
    'iia_active_list',
    'factsheet_list',
    'factsheet_view',
    'institution_list',
    'department_list',
  ],
}

const administrator = {
  role: 'IRO',
  permissions: [
    'profile_view',
    'profile_edit',
    'iia_list',
    'iia_create',
    'iia_view',
    'iia_edit',
    'iia_edit_iro',
    'ects_coordinators_view',
    'ects_coordinators_create',
    'iia_excel_export',
    'iia_active_list',
    'factsheet_list',
    'factsheet_view',
    'factsheet_edit',
    'institution_list',
    'institution_edit',
    'department_list',
    'department_edit',
    'test',
    'application_create',
    'application_edit',
    'application_list',
    'application_view',
    'application_settings'
  ],
}

const student = {
  role: 'Student',
  permissions: [
    'profile_view',
    'profile_edit',
    'ects_coordinators_view',
    'iia_active_list',
    'factsheet_list',
    'factsheet_view',
    'institution_list',
    'department_list',
    'application_create',
    'application_edit',
    'application_list',
    'application_view'
  ],
}


const _kc = new Keycloak(Config);



/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc.init({ checkLoginIframe: false })
    .then((authenticated) => {
      if (_kc.tokenParsed) {
        console.log(_kc.tokenParsed);
        // localStorage.setItem("access_token", _kc.token);
        const now = new Date();
        now.setTime(now.getTime() + 200 * 60 * 1000);
        setCookie('access_token', _kc.token, now.toUTCString())
        var user_data = {
          "family_name": _kc.tokenParsed.family_name,
          "given_name": _kc.tokenParsed.given_name,
          "email": _kc.tokenParsed.email
        };

        if ("authApm" in _kc.tokenParsed) {
          user_data.university_registry_number = _kc.tokenParsed.authApm;
        }
        else {
          user_data.university_registry_number = "123456789";
        }

        // only valid for auth students
        // change before students from other heis use application
        user_data.esi = "urn:schac:personalUniqueCode:int:esi:auth.gr:" + user_data.university_registry_number;

        if ("givenNameEl" in _kc.tokenParsed) {
          _kc.tokenParsed.givenNameEl.forEach(element => {
            if (element.localeCompare(_kc.tokenParsed.given_name) != 0) {
              user_data.given_name_gr = element;
            }
          });
        }
        if (user_data.given_name_gr == undefined) {
          user_data.given_name_gr = _kc.tokenParsed.given_name;
        }

        if ("snEl" in _kc.tokenParsed) {
          _kc.tokenParsed.snEl.forEach(element => {
            if (element.localeCompare(_kc.tokenParsed.family_name) != 0) {
              user_data.family_name_gr = element;
            }
          });
        }
        if (user_data.family_name_gr == undefined) {
          user_data.family_name_gr = _kc.tokenParsed.family_name;
        }

        setCookie('user', JSON.stringify(user_data), now.toUTCString())
        if (_kc.tokenParsed.scope.split(" ").filter(i => i.indexOf("mobisis") !== -1)[0] === "mobisis-teachers") {
          localStorage.setItem("role", JSON.stringify(contributor));
        }
        else if (_kc.tokenParsed.scope.split(" ").filter(i => i.indexOf("mobisis") !== -1)[0] === "mobisis-iro") {
          localStorage.setItem("role", JSON.stringify(administrator));
        }
        else if (_kc.tokenParsed.scope.split(" ").filter(i => i.indexOf("mobisis") !== -1)[0] === "mobisis-students") {
          localStorage.setItem("role", JSON.stringify(student));
        }
        else {
          localStorage.setItem("role", JSON.stringify(noRights));
        }
      }
      onAuthenticatedCallback()
    })
};

const doLogin = _kc.login;


const doLogout = () => {
  _kc.logout();
  localStorage.clear();
  setCookie('access_token', '', '')
};

// const getToken = () => _kc.token || localStorage.getItem("access_token");

const getToken = () => {
  const cookie = getCookie('access_token')
  if (cookie) {
    return cookie
  } else {
    doLogout()
  }
}

const isLoggedIn = () => !!_kc.token || getCookie('access_token');

const updateToken = (successCallback) =>
  _kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,

};

export default UserService;